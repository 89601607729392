<template>
  <div
    class="card mb-2 flex items-center justify-between gap-2 p-2 cursor-pointer"
    @click="handleNavigation"
  >
    <div class="flex items-center gap-2">
      <div>
        <img
          v-if="client.logo"
          class="avatar"
          :src="clientLogoUrl(client.logo)"
          alt=""
        >
        <div
          v-else
          class="avatar-letter"
        >
          {{ client.name[0] }}
        </div>
      </div>

      <div>
        <div class="name flex justify-between">
          <highlight-query
            :text="client.name"
            :search-string="searchString"
          />
        </div>

        <small class="sub-text inline-block mt-2">
          <highlight-query
            :text="client.industryCategory"
            :search-string="searchString"
          />
        </small>
      </div>
    </div>

    <badge
      v-if="client.companyGroup"
      variant="info"
      class="ml-1"
    >
      <highlight-query
        :text="client.companyGroup"
        :search-string="searchString"
      />
    </badge>
  </div>
</template>

<script>
import config from '@/config'
import { GlobalSearchAccess } from '@/components/global-search/mixins'
const { assetsRoot, clientLogoAssetPath } = config.assets

export default {
  name: 'ClientsSearchResult',

  mixins: [
    GlobalSearchAccess
  ],

  props: {
    client: {
      type: Object,
      required: true
    },
    searchString: {
      type: String,
      default: ''
    }
  },

  methods: {
    clientLogoUrl (clientLogo) {
      return `${assetsRoot}${clientLogoAssetPath}${clientLogo}`
    },

    handleNavigation () {
      this.$emit('navigation')
      this.$router.push({
        name: 'ClientInfo',
        params: { id: this.client.clientId }
      })
    }
  }
}
</script>

<style scoped>
@import "@/components/global-search/styles/global-search-results.css";
</style>
