export default {
  computed: {
    currentUserCompanyGroup () { return this.$store.state.User?.user?.companyGroup },
    currentUserCompany () { return this.currentUserCompanyGroup?.company },

    hasAccess () {
      return {
        requestForProposals:
          this.$permission.hasPermission('RequestForProposalViewAll') ||
          this.$permission.hasPermission('RequestForProposalViewForOwnCompany') ||
          this.$permission.hasPermission('RequestForProposalViewForOwnGroup') ||
          this.$permission.hasPermission('RequestForProposalViewOwn'),
        clients: true,
        campaigns: true,
        mediaAssets:
          this.$permission.hasPermission('AssetLibraryViewForAll') ||
          this.$permission.hasPermission('AssetLibraryViewForOwnCompany') ||
          this.$permission.hasPermission('AssetLibraryViewForOwnGroup'),
        contacts:
          this.$permission.hasPermission('ContactViewAll') ||
          this.$permission.hasPermission('ContactViewForOwnCompany') ||
          this.$permission.hasPermission('ContactViewForOwnGroup') ||
          this.$permission.hasPermission('ContactViewOwn'),
      }
    },

    enabledFeatures () {
      return {
        requestForProposals: this.currentUserCompany?.enableRequestForProposal,
        mediaAssets: this.currentUserCompany?.isAssetManagerEnabled
      }
    },

    requestForProposalsViewFilter () {
      return (
        this.$permission.hasPermission('RequestForProposalViewAll') ||
        this.$permission.hasPermission('RequestForProposalViewForOwnCompany') ||
        this.$permission.hasPermission('RequestForProposalViewForOwnGroup')
      )
        ? 'all'
        : 'mine'
    },

    campaignsViewFilter () {
      return (
        this.$permission.hasPermission('CampaignViewAll') ||
        this.$permission.hasPermission('CampaignViewForOwnCompany') ||
        this.$permission.hasPermission('CampaignViewForOwnGroup')
      )
        ? 'all'
        : 'mine'
    },

    clientsViewFilter () {
      return (
        this.$permission.hasPermission('ClientViewAll') ||
        this.$permission.hasPermission('ClientViewForOwnCompany') ||
        this.$permission.hasPermission('ClientViewForOwnGroup')
      )
        ? 'all'
        : 'mine'
    },

    companyId () {
      return this.$store.state.Workspace.filters.companyId || this.currentUserCompany.id
    },

    companyGroupId () {
      return this.$store.state.Workspace.filters.companyGroupId || this.currentUserCompanyGroup.id
    }
  }
}
