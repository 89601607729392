/* eslint-disable @stylistic/no-multi-spaces */
const types = [
  { ext: '.pdf',  iconGroup: 'PDF',      groups: ['document'],                mimeTypes: ['application/pdf'] },

  { ext: '.doc',  iconGroup: 'Word',     groups: ['document'],                mimeTypes: ['application/msword'] },
  { ext: '.docx', iconGroup: 'Word',     groups: ['document'],                mimeTypes: ['application/vnd.openxmlformats-officedocument.wordprocessingml.document'] },
  { ext: '.odt',  iconGroup: 'Word',     groups: ['document'],                mimeTypes: ['application/vnd.oasis.opendocument.text'] },

  { ext: '.csv',  iconGroup: 'Excel',    groups: ['document', 'csv', 'data'], mimeTypes: ['text/csv'] },
  { ext: '.xls',  iconGroup: 'Excel',    groups: ['document'],                mimeTypes: ['application/vnd.ms-excel'] },
  { ext: '.xlt',  iconGroup: 'Excel',    groups: ['document'],                mimeTypes: ['application/vnd.ms-excel.template'] },
  { ext: '.xlsm', iconGroup: 'Excel',    groups: ['document'],                mimeTypes: ['application/vnd.ms-excel.sheet.macroEnabled.12'] },
  { ext: '.xlsx', iconGroup: 'Excel',    groups: ['document'],                mimeTypes: ['application/vnd.openxmlformats-officedocument.spreadsheetml.sheet'] },
  { ext: '.xltx', iconGroup: 'Excel',    groups: ['document'],                mimeTypes: ['application/vnd.openxmlformats-officedocument.spreadsheetml.template'] },
  { ext: '.ods',  iconGroup: 'Excel',    groups: ['document'],                mimeTypes: ['application/vnd.oasis.opendocument.spreadsheet'] },

  { ext: '.ppt',  iconGroup: 'PPT',      groups: ['document'],                mimeTypes: ['application/vnd.ms-powerpoint'] },
  { ext: '.pptx', iconGroup: 'PPT',      groups: ['document'],                mimeTypes: ['application/vnd.openxmlformats-officedocument.presentationml.presentation'] },
  { ext: '.odp',  iconGroup: 'PPT',      groups: ['document'],                mimeTypes: ['application/vnd.oasis.opendocument.presentation'] },

  { ext: '.eml',  iconGroup: 'Outlook',  groups: ['email'],                   mimeTypes: ['message/rfc822'] },
  { ext: '.msg',  iconGroup: 'Outlook',  groups: ['email'],                   mimeTypes: ['application/vnd.ms-outlook', 'application/msg'] },

  { ext: '.json', iconGroup: 'JSON',     groups: ['document', 'data'],        mimeTypes: ['application/json'] },
  { ext: '.xml',  iconGroup: 'XML',      groups: ['document', 'data'],        mimeTypes: ['application/xml', 'text/xml'] },
  { ext: '.scx',  iconGroup: 'XML',      groups: ['document', 'data'],        mimeTypes: ['application/xml', 'text/xml'] },

  { ext: '.txt',  iconGroup: 'Text',     groups: ['text'],                    mimeTypes: ['text/plain'] },
  { ext: '.rtf',  iconGroup: 'Text',     groups: ['text'],                    mimeTypes: ['application/rtf'] },

  { ext: '.zip',  iconGroup: 'Zip File', groups: ['archive'],                 mimeTypes: ['application/zip', 'application/x-zip-compressed'] },

  { ext: '.jpg',  iconGroup: 'Image',    groups: ['image'],                   mimeTypes: ['image/jpeg'] },
  { ext: '.jpeg', iconGroup: 'Image',    groups: ['image'],                   mimeTypes: ['image/jpeg'] },
  { ext: '.gif',  iconGroup: 'Image',    groups: ['image'],                   mimeTypes: ['image/gif'] },
  { ext: '.png',  iconGroup: 'Image',    groups: ['image'],                   mimeTypes: ['image/png'] },
  { ext: '.webp', iconGroup: 'Image',    groups: ['image'],                   mimeTypes: ['image/webp'] },
  { ext: '.tiff', iconGroup: 'Image',    groups: ['image'],                   mimeTypes: ['image/tiff'] },
  { ext: '.svg',  iconGroup: 'Image',    groups: ['image'],                   mimeTypes: ['image/svg+xml'] },

  { ext: '.mov',  iconGroup: 'Video',    groups: ['video'],                   mimeTypes: ['video/quicktime'] },
  { ext: '.mp4',  iconGroup: 'Video',    groups: ['video'],                   mimeTypes: ['video/mp4'] },
  { ext: '.wmv',  iconGroup: 'Video',    groups: ['video'],                   mimeTypes: ['video/x-ms-wmv'] },
  { ext: '.mpg',  iconGroup: 'Video',    groups: ['video'],                   mimeTypes: ['video/mpg', 'video/mpeg'] },
  { ext: '.mpeg', iconGroup: 'Video',    groups: ['video'],                   mimeTypes: ['video/mpg', 'video/mpeg'] },
  { ext: '.m4v',  iconGroup: 'Video',    groups: ['video'],                   mimeTypes: ['video/x-m4v'] },
  { ext: '.webm', iconGroup: 'Video',    groups: ['video'],                   mimeTypes: ['video/webm'] },
  { ext: '.ogg',  iconGroup: 'Video',    groups: ['video'],                   mimeTypes: ['video/ogg'] },

  { ext: '.mp3',  iconGroup: 'Audio',    groups: ['audio'],                   mimeTypes: ['audio/mpeg'] },
  { ext: '.oga',  iconGroup: 'Audio',    groups: ['audio'],                   mimeTypes: ['audio/ogg'] },
  { ext: '.m4a',  iconGroup: 'Audio',    groups: ['audio'],                   mimeTypes: ['audio/x-m4a'] },
  { ext: '.ogg',  iconGroup: 'Audio',    groups: ['audio'],                   mimeTypes: ['audio/ogg'] },
  { ext: '.wav',  iconGroup: 'Audio',    groups: ['audio'],                   mimeTypes: ['audio/wav'] }
]
/* eslint-enable @stylistic/no-multi-spaces */

const aiParsableFiles = [
  'PDF',
  'Excel',
  'PowerPoint',
  'PPT',
  'Word'
]

const extToMimeType = types.reduce((m, type) => Object.assign(m, { [type.ext]: type.mimeTypes[0] }), {})
const extToIconGroup = types.reduce((m, type) => Object.assign(m, { [type.ext]: type.iconGroup }), {})

const groupToExt = {}
const groupToMimeType = {}

for (const type of types) {
  for (const group of type.groups) {
    groupToExt[group] ||= []
    if (!groupToExt[group].includes(type.ext)) {
      groupToExt[group].push(type.ext)
    }

    groupToMimeType[group] ||= []
    for (const mimeType of type.mimeTypes) {
      if (!groupToMimeType[group].includes(mimeType)) {
        groupToMimeType[group].push(mimeType)
      }
    }
  }
}

groupToExt.all = Array.from(new Set(Object.values(groupToExt).flat()))
groupToMimeType.all = Array.from(new Set(Object.values(groupToMimeType).flat()))

const config = {
  types,
  extToMimeType,
  extToIconGroup,
  groupToExt,
  groupToMimeType,
  aiParsableFiles
}

export default config
