import externalIntegrationInstallation from './externalIntegrationInstallation'

const settings = {
  email: {
    key: 'email',
    label: 'Email',
    templateSchema: {
      type: 'object',
      required: ['template', 'version'],
      properties: {
        template: {
          type: 'string',
          minLength: 1
        },
        version: {
          type: 'string',
          minLength: 1
        }
      }
    }
  },
  [externalIntegrationInstallation.settings.slack.key]: {
    key: externalIntegrationInstallation.settings.slack.key,
    label: externalIntegrationInstallation.settings.slack.label,
    templateSchema: {
      type: 'array',
      minItems: 1,
      items: {
        type: 'object',
        required: ['type'],
        properties: {
          type: {
            type: 'string',
            minLength: 1
          }
        }
      }
    }
  },
  [externalIntegrationInstallation.settings.microsoftTeams.key]: {
    key: externalIntegrationInstallation.settings.microsoftTeams.key,
    label: externalIntegrationInstallation.settings.microsoftTeams.label,
    templateSchema: {
      type: 'object',
      required: ['$schema', 'type', 'version', 'body'],
      properties: {
        $schema: {
          type: 'string',
          const: 'http://adaptivecards.io/schemas/adaptive-card.json'
        },
        type: {
          type: 'string',
          enum: ['AdaptiveCard']
        },
        version: {
          type: 'string'
        },
        body: {
          type: 'array',
          items: {
            type: 'object',
            required: ['type'],
            properties: {
              type: {
                type: 'string'
              }
            }
          }
        }
      }
    }
  }
}

const keys = Object.values(settings).map(o => o.key)
const types = Object.values(settings).reduce((m, o) => Object.assign(m, { [o.label]: o.key }), {})

export default {
  settings,
  keys,
  types
}
