export default class ShortcutDetector {
  constructor (targetSequence, eventName) {
    this.targetSequence = targetSequence
    this.eventName = eventName || targetSequence.join('')
    this.buffer = []
    this.maxSize = targetSequence.length
  }

  addKey (key) {
    this.buffer.push(key)
    if (this.buffer.length > this.maxSize) {
      this.buffer.shift()
    }
  }

  isSequenceMatched () {
    return this.buffer.join('') === this.targetSequence.join('')
  }
}
