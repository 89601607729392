<template>
  <div
    class="card mb-2 flex items-center justify-between gap-2 p-2 cursor-pointer"
    @click="handleNavigation"
  >
    <div class="flex items-center gap-2">
      <div>
        <div class="avatar-letter">
          {{ contact.initials }}
        </div>
      </div>

      <div>
        <div class="name">
          <highlight-query
            :text="contact.fullName"
            :search-string="searchString"
          />

          <badge
            v-if="contact.title"
            variant="info"
            class="ml-1"
          >
            <highlight-query
              :text="contact.title"
              :search-string="searchString"
            />
          </badge>
        </div>
      </div>
    </div>

    <div class="ml-2 flex gap-2">
      <contact-methods-table-display
        type="email"
        :show-primary="false"
        :contact-methods="contact.contactMethods || []"
      />

      <contact-methods-table-display
        type="phone"
        :show-primary="false"
        :contact-methods="contact.contactMethods || []"
      />
    </div>
  </div>
</template>

<script>
import { GlobalSearchAccess } from '@/components/global-search/mixins'
import { ContactMethodsTableDisplay } from '@/components/workspace/contact'

export default {
  name: 'ContactSearchResult',

  components: {
    ContactMethodsTableDisplay
  },

  mixins: [
    GlobalSearchAccess
  ],

  props: {
    contact: {
      type: Object,
      required: true
    },
    searchString: {
      type: String,
      default: ''
    }
  },

  methods: {
    handleNavigation () {
      this.$emit('navigation')
      this.$router.push({
        name: 'WorkspaceAllContacts',
        query: { contactId: this.contact.id }
      })
    }
  }
}
</script>

<style scoped>
@import "@/components/global-search/styles/global-search-results.css";
</style>
