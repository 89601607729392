const TYPES = Object.freeze({
  New: {
    key: 'new',
    label: 'New'
  },
  PendingSubmission: {
    key: 'pending-submission',
    label: 'Pending Submission'
  },
  Submitted: {
    key: 'submitted',
    label: 'Submitted'
  },
  Editing: {
    key: 'editing',
    label: 'Editing'
  },
  ClosedLost: {
    key: 'closed-lost',
    label: 'Closed Lost'
  },
  ClosedWon: {
    key: 'closed-won',
    label: 'Closed Won'
  },
  Open: {
    key: 'open',
    label: 'Open'
  },
  ESignature: {
    key: 'e-signature',
    label: 'E-Signature'
  }
})

export default {
  types: TYPES,
  typeKeys: Object.keys(TYPES).map(key => TYPES[key].key)
}
