import Vue from 'vue'

function loadBlockedMessage () {
  const path = '/user/blocked-message'
  return Vue.api.get(path)
}

export default {
  loadBlockedMessage
}
