<template>
  <div
    class="card mb-2 flex items-center justify-between gap-2 p-2 cursor-pointer"
    @click="handleNavigation"
  >
    <div class="flex items-center gap-2">
      <div>
        <img
          v-if="campaign.clientLogo"
          class="avatar"
          :src="clientLogoUrl(campaign.clientLogo)"
          alt=""
        >
        <div
          v-else
          class="avatar-letter"
        >
          {{ campaign.clientName[0] }}
        </div>
      </div>

      <div>
        <div class="name">
          <highlight-query
            :text="campaign.name"
            :search-string="searchString"
          />
        </div>

        <div class="mt-2">
          <span
            v-if="campaign.ownerFirstName"
            class="sub-text"
          >
            <small>
              <span class="accent">Client</span>:
              <highlight-query
                :text="campaign.clientName"
                :search-string="searchString"
              />
            </small>
          </span>
          <span
            v-if="campaign.ownerFirstName"
            class="sub-text"
          >
            <small>, <span class="accent">Owner</span>:
              <highlight-query
                :text="`${ campaign.ownerFirstName } ${campaign.ownerLastName}`"
                :search-string="searchString"
              />
            </small>
          </span>
        </div>
      </div>
    </div>

    <div class="flex flex-col items-end ml-2">
      <status-badge variant="info">
        {{ campaign.status }}
      </status-badge>
    </div>
  </div>
</template>

<script>
import config from '@/config'
import { GlobalSearchAccess } from '@/components/global-search/mixins'
const { assetsRoot, clientLogoAssetPath } = config.assets

export default {
  name: 'CampaignsSearchResult',

  mixins: [
    GlobalSearchAccess
  ],

  props: {
    campaign: {
      type: Object,
      required: true
    },
    searchString: {
      type: String,
      default: ''
    }
  },

  methods: {
    clientLogoUrl (clientLogo) {
      return `${assetsRoot}${clientLogoAssetPath}${clientLogo}`
    },

    handleNavigation () {
      this.$emit('navigation')
      this.$router.push({
        name: 'CampaignSummary',
        params: { id: this.campaign.id }
      })
    }
  }
}
</script>

<style scoped>
@import "@/components/global-search/styles/global-search-results.css";
</style>
