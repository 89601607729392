<template>
  <a-dialog
    v-if="isAccessBlocked"
    ref="dialog"
    overlay="dark"
    type="danger"
    vertical-height="grow"
    horizontal-width="grow"
    :primary-button="false"
    :prevent-close="true"
    class="dialog"
  >
    <template #header>
      Access Blocked
    </template>

    <template #content>
      <div v-html="blockedMessage" />
    </template>
  </a-dialog>
</template>

<script>
const DEFAULT_MESSAGE = 'Access has been blocked'

export default {
  data () {
    return {
      blockedMessage: DEFAULT_MESSAGE
    }
  },

  computed: {
    isAccessBlocked () {
      return this.$store.state.isUserAuthenticated &&
        this.$permission.hasPermission('AccessBlocked')
    }
  },

  watch: {
    isAccessBlocked (val) {
      if (val) {
        this.loadBlockedMessage()
      }
    }
  },

  async created () {
    if (this.isAccessBlocked) {
      this.loadBlockedMessage()
    }
  },

  methods: {
    async loadBlockedMessage () {
      const result = await this.$api.user.loadBlockedMessage()
      this.blockedMessage = result?.message || DEFAULT_MESSAGE
    }
  }
}
</script>
