import { cloneDeep } from 'lodash-es'

const ClonePlugin = {
  install (Vue, options) {
    Vue.prototype.$cloneObject = (obj) => {
      return Object.assign({}, obj)
    }

    Vue.prototype.$cloneDeepObject = (obj) => {
      return cloneDeep(obj)
    }

    Vue.prototype.$cloneArray = (arr) => {
      return arr.slice(0)
    }
  }
}

export default ClonePlugin
