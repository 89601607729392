import Vue from 'vue'

function list (params = {}) {
  const path = '/admin/fulfillment-vendors'
  return Vue.api.get(path, { params })
}

function load (id) {
  const path = `/admin/fulfillment-vendors/${id}`
  return Vue.api.get(path)
}

function create (data = {}) {
  const path = '/admin/fulfillment-vendors'
  return Vue.api.post(path, { data })
}

function _delete (id) {
  const path = `/admin/fulfillment-vendors/${id}`
  return Vue.api.delete(path)
}

function update (id, data = {}) {
  const path = `/admin/fulfillment-vendors/${id}`
  return Vue.api.patch(path, { data })
}

export default {
  list,
  load,
  create,
  delete: _delete,
  update
}
