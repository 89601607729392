const settings = {
  slack: {
    key: 'slack',
    label: 'Slack'
  },
  microsoftTeams: {
    key: 'microsoftTeams',
    label: 'Microsoft Teams'
  },
}

const keys = Object.values(settings).map(o => o.key)
const types = Object.values(settings).reduce((m, o) => Object.assign(m, { [o.label]: o.key }), {})

export default {
  settings,
  keys,
  types
}
