<template>
  <div>
    <div
      v-if="total > 10 || title"
      class="flex justify-between mb-5"
    >
      <small
        v-if="title"
        class="accent"
      >{{ title }}</small>
      <span v-else>Showing top 10 results</span>
      <small
        v-if="!title || (title && total > 3)"
        class="view-all"
        @click="handleClick"
      >View All ({{ total }}) results</small>
    </div>

    <div v-if="data.length">
      <div
        v-for="item in data"
        :key="item.id"
      >
        <slot
          name="row"
          v-bind="item"
        />
      </div>
    </div>
    <global-search-empty v-else />
  </div>
</template>

<script>
import GlobalSearchEmpty from '@/components/global-search/GlobalSearchEmpty.vue'

export default {
  name: 'GlobalSearchList',

  components: {
    GlobalSearchEmpty
  },

  props: {
    title: {
      type: String,
      default: ''
    },
    total: {
      type: Number,
      required: true
    },
    data: {
      type: Array,
      required: true
    }
  },

  methods: {
    handleClick () {
      this.$emit('view-all')
    }
  }
}
</script>

<style scoped>
@import "@/components/global-search/styles/global-search-results.css";
</style>
