<template>
  <overlay-pane
    class="overlay-pane-main d-flex flex-row"
    :show="show"
    width="25%"
    @close="close"
  >
    <div
      ref="taskPanel"
      class="d-flex flex-column tasks-pane header-tab"
      @touchmove.prevent
    >
      <div class="header-slot">
        <div class="d-flex flex-row align-items-center header-row">
          <div class="header-text">
            My Tasks
          </div>
        </div>
        <div class="wrapper-header" />
      </div>
      <div
        ref="scrollable"
        class="main-slot main-tab"
        @scroll="enforceScroll"
      >
        <div
          class="navbar"
        >
          <div class="d-flex flex-row section-container">
            <div
              v-for="tab in navTabs"
              :key="tab.value"
              :class="`d-flex justify-content-center tab ${(activeTab ===tab.value? 'tab-active': 'tab-inactive')}`"
            >
              <div
                class="tab-label"
                @click="setActiveTab(tab.value,tab.disabled)"
              >
                <div style="padding-right: 10px;">
                  {{ tab.name }}
                </div>
              </div>
            </div>
          </div>
        </div>
        <page-spinner
          v-if="!isLoaded"
        />
        <div
          v-else
          class="inline-block min-w-full"
          style="margin-top:2em;"
        >
          <div class="task-count">
            Showing {{ tasksCount }} tasks
          </div>
          <div
            v-if="tasks.length === 0 && (activeTab === 'assigned' || (activeTab === 'completed' && !hasCompletedWelcomeTask)|| (activeTab === 'myTasks' && hasCompletedWelcomeTask))"
            class="bg-white border-gray-200 h-96 flex flex-col items-center justify-center"
          >
            <task-icon
              w="60"
              h="60"
            />
            <div v-if="activeTab === 'myTasks'">
              <h6 class="max-w-80 text-center mt-8">
                Ready to tackle your day?
              </h6> <br>
              <h6 class="text-center">
                Get Started by creating a task below.
              </h6>
            </div>
            <div v-if="activeTab==='assigned'">
              <h6 class="text-center mt-8">
                Tasks you have delegated to others will appear here!
              </h6> <br>
              <h6 class="text-center">
                Click "Create Task" below to get started.
              </h6>
            </div>
            <div v-if="activeTab==='completed'">
              <h6 class="text-center mt-8">
                Tasks you have completed will appear here.
              </h6> <br>
              <h6 class="text-center">
                Start checking off those to-dos to populate this list!
              </h6>
            </div>
          </div>
          <div
            v-else
          >
            <div
              v-for="(task,index) in tasks"
              :key="task.id"
            >
              <task-card
                v-if="task.status!='welcome'"
                :id="`task_${task.id}`"
                :ref="`task_${index}`"
                :task="task"
                :active-tab="activeTab"
                @click="completeTask"
                @edit="editTask"
                @delete="deleteTask"
                @close="close"
              />
            </div>
          </div>
          <div v-if="activeTab==='myTasks'">
            <welcome-task-card
              v-if="!hasCompletedWelcomeTask"
              :active-tab="activeTab"
              @complete="userCompletedWelcomeTask"
            />
          </div>
          <div v-if="activeTab==='completed'">
            <welcome-task-card
              v-if="hasCompletedWelcomeTask"
              :active-tab="activeTab"
            />
          </div>
        </div>
      </div>
      <div class="footer-slot">
        <div class="wrapper-close" />
        <div
          class="control-buttons"
        >
          <div>
            <a-button
              variant="default"
              class="close-task"
              @click="close"
            >
              Close
            </a-button>
          </div>
          <div class="mx-3">
            <a-button
              variant="primary"
              class="create-task-button"
              @click="taskToEdit=null;showCreateTaskModal=true"
            >
              <div class="d-flex flex align-items-center">
                <task-create-icon
                  w="24"
                  h="24"
                />
                <div class="create-task">
                  Create Task
                </div>
              </div>
            </a-button>
          </div>
        </div>
      </div>
      <create-task-modal
        v-if="showCreateTaskModal"
        :task="taskToEdit"
        @cancel="showCreateTaskModal=false;taskToEdit=null"
        @created="taskCreated"
        @updated="taskUpdated"
      />
    </div>
  </overlay-pane>
</template>
<script>
import { TaskIcon, TaskCreateIcon } from '@/components/icons'
import CreateTaskModal from './CreateTaskModal.vue'
import TaskCard from './TaskCard.vue'
import WelcomeTaskCard from './WelcomeTaskCard.vue'
import { mapActions } from 'vuex'
import dayjs from 'dayjs'
import { remove } from 'lodash-es'

export default {
  components: {
    CreateTaskModal,
    TaskIcon,
    TaskCard,
    TaskCreateIcon,
    WelcomeTaskCard
  },
  props: {
    show: {
      type: Boolean,
      required: true
    },
    taskId: {
      type: Number,
      required: false,
      default: null
    }

  },

  data () {
    return {
      isLoaded: false,
      activeTab: 'myTasks',
      navTabs: [{ name: 'My Tasks', value: 'myTasks' }, { name: 'Assigned', value: 'assigned' }, { name: 'Completed', value: 'completed' }],
      showCreateTaskModal: false,
      tasks: [],
      taskToEdit: null,
      isExpanded: false,
      hasCompletedWelcomeTask: false
    }
  },
  computed: {
    user () {
      return this.$store.state.User.user
    },
    tasksCount () {
      return this.tasks.length > 0 ? this.tasks.length + ((this.activeTab === 'assigned' || (this.activeTab === 'completed' && !this.hasCompletedWelcomeTask) || (this.activeTab === 'myTasks' && this.hasCompletedWelcomeTask) ? 0 : 1)) : (this.activeTab === 'assigned' || (this.activeTab === 'completed' && !this.hasCompletedWelcomeTask) || (this.activeTab === 'myTasks' && this.hasCompletedWelcomeTask) ? 0 : 1)
    }
  },

  watch: {
    async show (showState) {
      if (showState) await this.loadUserTasks()
    }
  },
  async created () {
    await this.loadUserTasks()
  },
  methods: {
    async loading (fn) {
      this.isLoaded = false
      await fn()
      this.isLoaded = true
    },

    shouldOpenToCompleted (userTask) {
      return userTask.isCompleted
    },

    shouldOpenToAssigned (userTask) {
      remove(userTask.assignedUsers, { id: this.user.id })

      return userTask.assignedUsers.length > 0 &&
        userTask.createdByUser?.id === this.user.id
    },

    async scrollToTask (id) {
      if (!this.tasks) await this.loadUserTasks()
      const userTask = await this.getUserTask(id)

      this.$router.push({
        path: this.$route.path,
        query: { taskId: id }
      }).catch(() => {})

      if (!userTask) return

      if (this.shouldOpenToCompleted(userTask)) {
        await this.setActiveTab('completed')
      } else if (this.shouldOpenToAssigned(userTask)) {
        await this.setActiveTab('assigned')
      } else {
        await this.setActiveTab('myTasks')
      }

      if (!this.tasks?.length) { return }

      let taskIndex = -1
      if (userTask.id) {
        taskIndex = this.tasks.findIndex(x => x.id === userTask.id)
      }
      const lastTask = 'task_' + (taskIndex >= 0 ? taskIndex : (this.tasks?.length - 1))
      const el = (this.$refs && this.$refs[lastTask] && this.$refs[lastTask][0]
        ? this.$refs[lastTask][0]
        : null)?.$el // VueComponent into DOM element

      if (el) {
        this.$nextTick(() => { el.scrollIntoView({ behavior: 'smooth' }) })
      }
    },

    async loadUserTasks () {
      await this.loading(async () => {
        this.tasks = []
        this.tasks = await this.getUserTasks({ taskFilter: this.activeTab })
        this.hasCompletedWelcomeTask = !!this.user?.hasCompletedWelcomeTask
      })
    },

    getDate (timestamp) {
      return dayjs(timestamp).format('MM/D')
    },

    formatDate (datetime) {
      return datetime && dayjs(datetime).format('YYYY-MM-DD')
    },

    toggleExpansion () {
      this.isExpanded = !this.isExpanded
    },

    calculateNumLines () {
      const taskDetails = this.$refs.taskDetails
      if (taskDetails) {
        const textHeight = taskDetails.offsetHeight
        const lineHeight = parseFloat(window.getComputedStyle(taskDetails).lineHeight)
        const numLines = Math.ceil(textHeight / lineHeight)
        return numLines
      }
      return 0
    },

    onMouseDown (e) {
      if (this.$refs.taskPanel && !(e.target === this.$refs.taskPanel || this.$refs.taskPanel.contains(e.target))) {
        this.close()
      }
    },

    enforceScroll (event) {
      const element = event.currentTarget
      const availableScroll = element.scrollHeight - element.offsetHeight
      const scrolled = element.scrollTop
      if (availableScroll === 0) {
        return
      }
      event.stopPropagation()
      if (scrolled === 0) {
        element.scrollTop = 1
      } else if (scrolled === availableScroll) {
        element.scrollTop = scrolled - 1
      }
    },

    async scrollToElement (taskId) {
      if (!this.taskToEdit && (taskId && this.activeTab !== 'myTasks')) {
        await this.setActiveTab('myTasks')
      }

      if (!this.tasks?.length) {
        await this.loadUserTasks()
      }

      let taskIndex = -1
      taskIndex = this.tasks.findIndex(task => task.id === taskId)
      if (taskIndex > -1) {
        const task = 'task_' + (taskIndex >= 0 ? taskIndex : (this.tasks?.length - 1))

        const el = document.getElementById('task_' + taskId)

        if (el) {
          const taskRef = this.$refs && this.$refs[task]
            ? this.$refs[task]
            : null

          this.$nextTick(() => {
            if (taskRef && taskRef[0]) taskRef[0].toggleExpansion()
            el.scrollIntoView({ behavior: 'smooth' })
          })
        }
      }
    },

    async setActiveTab (tabName, disabled) {
      if (disabled) { return }
      this.activeTab = tabName
      await this.loadUserTasks()
    },

    async taskCreated (taskId) {
      this.showCreateTaskModal = false
      await this.loadUserTasks()
      this.$messaging.addSuccessMessage('Task created!')
      await this.scrollToElement(taskId)
    },

    async taskUpdated (taskId) {
      this.showCreateTaskModal = false
      await this.loadUserTasks()
      this.$messaging.addSuccessMessage('Task updated!')
      await this.scrollToElement(taskId)
    },

    async completeTask (taskId) {
      await this.completeUserTask(taskId).then(async () => {
        await this.loadUserTasks()
        this.$messaging.addSuccessMessage('Well done! Task completed.')
      })
    },

    async deleteTask (taskId) {
      await this.deleteUserTask(taskId).then(async () => {
        await this.loadUserTasks()
        this.$messaging.addSuccessMessage('Task deleted!')
      })
    },

    async userCompletedWelcomeTask () {
      this.hasCompletedWelcomeTask = true
      await this.userHasCompletedWelcomeTask()
      this.$messaging.addSuccessMessage('Well done! Task completed.')
    },

    editTask (task) {
      this.taskToEdit = task
      this.showCreateTaskModal = true
    },

    close () {
      this.showCreateTaskModal = false
      this.tasks.forEach(element => { element.isUnread = false })

      const query = Object.assign({}, this.$route.query)
      delete query.taskId

      this.$router.replace({ query })
        .catch(() => {})

      this.$emit('close')
    },

    ...mapActions([
      'getUserTasks',
      'getUserTask',
      'completeUserTask',
      'deleteUserTask',
      'userHasCompletedWelcomeTask'
    ])
  }
}
</script>
  <style scoped>
  .overlay-pane-main :deep(.close-button) {
    top: 10px;
    right: 15px;
  }
  .overlay-pane-main :deep(.overlay-pane) {
    top: 60px;
    padding: 20px 0;
    box-shadow: 0px 0px 0px 0px rgba(0,0,0,0.40);
    overflow-x: hidden;
  }
  .overlay-pane-main :deep(.hidden-content-overlay) {
      top:60px;
  }

  .header-tab {
    height: 100%;
    display: flex;
    flex-direction: column;
    flex: none;
  }
  .tasks-pane {
    padding: 0px 0px 0px 0px;
  }
  .header-row {
    margin-bottom: 15px;
  }
  .main-slot {
    padding: 0px 6px;
  }
  .main-tab {
    flex-grow: 1;
    overflow: auto;
    overflow-x: hidden !important;
    -webkit-overflow-scrolling: touch;
  }
  .footer-slot {
    padding: 10px 6px 0px 0px;
    display:table-cell;
    text-align: center;
    margin-bottom: 50px;
  }
  .wrapper-close{
    border-bottom: 1px solid #ccc;
    margin-left: -20px;
    margin-right: -25px;
  }
  .wrapper-header{
    border-bottom: 1px solid #ccc;
  }
  .close {
    margin-top: 10px;
  }
  .header-text {
    font-size: 20px;
    font-weight: 500;
  }
  .bottom-message{
    margin-top: 20%;
    display:flex;
    justify-content: center;
    color: rgb(97, 91, 91);
    font-style: italic;
  }
  .scroll-top{
    margin-top: 2%;
    text-decoration: underline;
    cursor: pointer;
    font-style: italic;
  }
  .header-slot {
    padding: 10px 26px;
  }

  .task {
    margin: 0 20px;
  }
  .task-under-construction{
    position:fixed;
    top: 50%;
    margin-left:10%;
  }
  .inline-block {
    display: inline-block;
  }
  .min-w-full {
    min-width: 100%;
  }
  .align-middle {
    vertical-align: middle;
  }
  .control-buttons{
    margin-top: 3%;
    display:flex;
    justify-content: center;
  }
  .create-task{
    margin-left: 0.5em;
  }

  .create-task-button{
    max-height: 3.2em;
  }
  .pill{
    margin-left: 2%;
    color: rgba(46, 118, 188, 1);
    border: 2px solid rgba(46, 118, 188, 1) ;
    border-radius: 10px;
    padding: 10px;
    cursor: pointer;
  }
  .pill-active{
    margin-left: 2%;
    color: rgba(46, 118, 188, 1);
    border: 2px solid rgba(46, 118, 188, 1) ;
    border-radius: 10px;
    padding: 10px;
    cursor: pointer;
    background:rgba(236, 242, 248, 1) ;
  }
 .task-count{
    margin-left: 1em;
    margin-bottom:1em;
    font-size: 13px;
    font-weight: 400;
 }

.header, .footer {
  padding: 10px;
  align-items: center;
  height: 4em;
}

.header {
  display:flex;
  justify-content: space-between;
  border-bottom: 1px solid #ccc;
  border-top-left-radius: 10px;
  border-top-right-radius: 10px;
}
.header-expanded{
  height: auto;
}

.footer {
  border-top: 1px solid #ccc;
  border-bottom-left-radius: 10px;
  border-bottom-right-radius: 10px;
  font-size: 12px;
  font-weight: 400;
  color: rgba(79, 108, 136, 1);
  justify-content: space-between;
}

.content {
  flex: 1;
  padding: 20px;
}
.checkbox {
  accent-color: var(--checkbox-checked-background-color);
}
.checkbox:disabled {
  accent-color: rgba(67,160,71,0.7) !important;
}
.text-blue-500 {
  color: var(--link-color);
}
.expandable-box {
  height: 44px;
  overflow: hidden;
  padding: 7px 22px 10px 10px;
  font-style: italic;
  line-height: 1.5;
}

.expandable-box-show {
  height: auto;
  overflow: hidden;
  padding: 10px 22px 10px 10px;
  font-style: italic;
  line-height: 1.5;

}
.comment-wrapper{
  position: relative;
  margin-bottom: 10px;
  width: 100%;
  background-color: rgb(232, 232, 232);
}
.expand-button{
  position: absolute;
  top: 5px;
  left: 92%;
}
.taskDetails{
  line-height: 1.7;
  width: 10em;
  overflow-x: hidden;
}
.center-column {
  flex-grow: 2;
  width: 14em;
}
.name {
  font-weight: 500;
  align-self: flex-start;
  line-height: 1.3em;
}
.assignee-row {
  display: flex;
  height: 45px;
  align-items: center;
}
.left-column {
  flex-grow: 0;
  flex-shrink: 0;
  flex-basis: 45px;
  padding-left: 0.3rem;
}
.avatar {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  position: relative;
  width: 32px;
  height: 32px;
  border-radius: 50%;
  background-color: rgba(217, 217, 217, 1);
  background-size: cover;
  background-position: 50% 50%;
}

.avatar-initial {
  font-size: 13px;
  line-height: 23px;
  font-weight: 400;
  color: rgba(0, 0, 0, 1);
  z-index: 1;
  text-transform: uppercase;
}
.avatar-image {
  position: absolute;
  top: 0px;
  z-index: 2;
}
.navbar{
  margin-top:0px;
}
.section-container{
  font-size:13px;
  font-weight: 400;
  justify-content: center;
  border-bottom: 1px solid rgb(208, 208, 208);
  /* margin-bottom: 20px; */
}
.tab {
  position: relative;
  cursor: pointer;
  margin: 10px 5px;
  padding: 5px 0px 5px 0px;
  font-weight: 300;

}
.tab p {
  color: var(--link-color);
  margin: 3px auto;
}
.tab:after {
  position: absolute;
  left: 50%;
  content: '';
  height: 3px;
  width: 0;
  bottom: -10px;
  background: #2e76bc;
}
.tab p:hover {
  color: var(--link-active-color);
}
.tab.tab-active p {
  color: var(--text-color);
  font-weight: 500
}
.tab-active:after {
  flex: 1;
  position: absolute;
  bottom: -10px;
  width: 100%;
  left: -4px;
  opacity: 1;
}
.tab-content {
 padding:25px 0;
}
.tab-label{
  padding:3px;
   display: flex;
}
.tab-inactive{
  color: var(--link-color);
  font-weight: 500;
}

.tab-inactive>.counter{
    background: #e0e4e4;
}

.text-wrapper {
  width: 25em;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}

.truncate-text {
  margin: 0;
}
.blue-dot {
  min-width: 10px;
  height: 10px;
  border-radius: 100%;
  margin-top: 10px;
  margin-right: 8px;
  background-color: #2e76bc;
}

  </style>
