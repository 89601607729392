<template>
  <div
    class="card mb-2 flex items-center justify-between gap-2 p-2 cursor-pointer"
    @click="handleClick"
  >
    <div class="flex items-center gap-2">
      <div>
        <file-type-icon
          size="medium"
          :type="file.mediaAsset.fileType"
        />
      </div>

      <div>
        <div class="name">
          <highlight-query
            :text="file.mediaAsset.filename"
            :search-string="searchString"
          />

          <badge
            v-if="file.mediaAsset.internal"
            class="ml-2"
            variant="info"
          >
            Internal
          </badge>
        </div>

        <small class="sub-text inline-block mt-2">
          {{ file.mediaAsset.fileType }}
        </small>
      </div>
    </div>

    <div class="ml-2">
      <div
        v-if="file.mediaAsset.tags?.length === 0"
        class="d-flex items-center gap-2 sub-text"
      >
        <tag-icon
          w="24"
          h="24"
          class="sub-text"
        />
        <span class="mb-1">0</span>
      </div>
      <a-tooltip
        v-else
        position="top"
        custom
        :timeout-duration="200"
      >
        <template #tip>
          <div class="flex flex-col p-4">
            <div class="text-gray-400 sub-text">
              Tags ({{ file.mediaAsset.tags?.length }}):
            </div>
            <div>
              <span
                v-for="(tag, tagIdx) in file.mediaAsset.tags"
                :key="tagIdx"
                class="tag mt-2 px-3 py-1 rounded-full"
                :class="{ 'mr-2': tagIdx + 1 < file.mediaAsset.tags.length }"
              >
                {{ tag.name }}
              </span>
            </div>
          </div>
        </template>
        <template #hover-item>
          <div
            class="d-flex items-center gap-2 accent"
          >
            <tag-icon
              w="24"
              h="24"
              class="accent"
            />
            <span class="mb-1">
              {{ file.mediaAsset.tags?.length }}
            </span>
          </div>
        </template>
      </a-tooltip>
    </div>
  </div>
</template>

<script>
import { GlobalSearchAccess } from '@/components/global-search/mixins'
import FileTypeIcon from '@/components/icons/FileTypeIcon.vue'
import { TagIcon } from '@/components/icons/index'

export default {
  name: 'FilesSearchResult',

  components: {
    FileTypeIcon,
    TagIcon
  },

  mixins: [
    GlobalSearchAccess
  ],

  props: {
    file: {
      type: Object,
      required: true
    },
    searchString: {
      type: String,
      default: ''
    }
  },

  methods: {
    handleClick () {
      if (this.file.mediaAsset.fileType === 'External') this.openLinkInNewTab(this.file.mediaAsset.url)
      else this.downloadFile(this.file.mediaAsset)
    },

    openLinkInNewTab (url) {
      window.open(url, '_blank')
    },

    downloadFile (item) {
      this.$fileUtil.download(item.url)
    },
  }
}
</script>

<style scoped>
@import "@/components/global-search/styles/global-search-results.css";
</style>
