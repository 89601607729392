import audience from './audience'
import backendConfig from './backendConfig'
import bookmarks from './bookmarks'
import campaign from './campaign'
import campaignStatuses from './campaignStatuses'
import clientCategories from './clientCategories'
import company from './company'
import connect from './connect'
import customFields from './customFields'
import exports from './exports'
import externalApi from './externalApi'
import externalCrm from './externalCrm'
import fulfillmentVendors from './fulfillmentVendors'
import goal from './goal'
import internalAdmin from './internalAdmin'
import notificationConfigurations from './notification-configurations'
import notifications from './notifications'
import permission from './permission'
import presentation from './presentation'
import product from './product'
import recommendation from './recommendation'
import reporting from './reporting'
import sso from './sso'
import styles from './styles'
import submission from './submission'
import supportUser from './supportUser'
import tag from './tag'
import user from './user'
import userRoles from './userRoles'

export default [
  {
    path: '/admin',
    name: 'Admin',
    component: { render: (h) => h('router-view') },
    children: [
      {
        path: 'home',
        alias: '',
        name: 'AdminHome',
        component: () => import('@/views/admin/Home.vue')
      },

      ...company,
      ...user,
      ...userRoles,
      ...audience,
      ...presentation,
      ...campaignStatuses,
      ...recommendation,
      ...exports,
      ...permission,
      ...product,
      ...goal,
      ...styles,
      ...submission,
      ...sso,
      ...tag,
      ...clientCategories,
      ...supportUser,
      ...externalApi,
      ...externalCrm,
      ...connect,
      ...notifications,
      ...notificationConfigurations,
      ...customFields,
      ...reporting,
      ...campaign,
      ...bookmarks,
      ...internalAdmin,
      ...backendConfig,
      ...fulfillmentVendors
    ],
    meta: {
      headerText: 'Admin',
      navItem: 'Admin',
      permRequired: true
    }
  }
]
