<template>
  <div class="flex w-full items-center justify-center bg-blue-100">
    <img src="/images/logos/advisr-icon.png">
  </div>
</template>

<script>
export default {
  name: 'KonamiCode',
}
</script>

<style scoped>

</style>
