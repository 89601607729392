import Vue from 'vue'

function list (params = {}) {
  const path = '/admin/reporting/dashboards'
  return Vue.api.get(path, { params })
}

function create (data = {}) {
  const path = '/admin/reporting/dashboards'
  return Vue.api.post(path, { data })
}

function load (id) {
  const path = `/admin/reporting/dashboards/${id}`
  return Vue.api.get(path)
}

function update (id, data) {
  const path = `/admin/reporting/dashboards/${id}`
  return Vue.api.patch(path, { data })
}

function _delete (id) {
  const path = `/admin/reporting/dashboards/${id}`
  return Vue.api.delete(path)
}

export default {
  list,
  create,
  load,
  update,
  delete: _delete
}
