import backendConfig from './backendConfig'
import emailTemplateMappings from './emailTemplateMappings'
import externalIntegrationInstallations from './externalIntegrationInstallations'
import externalIntegrationUserMappings from './externalIntegrationUserMappings'
import externalIntegrationUsers from './externalIntegrationUsers'
import externalSystems from './externalSystems'
import fulfillmentVendors from './fulfillmentVendors'
import notificationStrategyConfigurations from './notificationStrategyConfigurations'
import products from './products'
import recommendations from './recommendations'
import reporting from './reporting'
import tasks from './tasks'
import userNotificationTemplates from './userNotificationTemplates'
import userNotificationTypes from './userNotificationTypes'
import users from './users'

export default {
  backendConfig,
  emailTemplateMappings,
  externalIntegrationInstallations,
  externalIntegrationUserMappings,
  externalIntegrationUsers,
  externalSystems,
  fulfillmentVendors,
  notificationStrategyConfigurations,
  products,
  recommendations,
  reporting,
  tasks,
  userNotificationTemplates,
  userNotificationTypes,
  users
}
