const TYPES = Object.freeze({
  productSpend: {
    key: 'spend',
    label: 'Product Spend'
  },
  productRanking: {
    key: 'ranking',
    label: 'Product Ranking'
  },
  budgetBaseline: {
    key: 'budget-baseline',
    label: 'Budget Baseline'
  },
  budgetInfluence: {
    key: 'budget-influence',
    label: 'Budget Influence'
  }
})

const AGGREGATE_FUNCTIONS = Object.freeze({
  sum: {
    key: 'sum',
    label: 'Sum'
  },
  average: {
    key: 'mean',
    label: 'Average'
  },
  max: {
    key: 'max',
    label: 'Maximum'
  },
  min: {
    key: 'min',
    label: 'Minimum'
  }
})

export default {
  types: TYPES,
  typeKeys: Object.keys(TYPES).map(key => TYPES[key].key),
  aggregateFunctions: AGGREGATE_FUNCTIONS,
  aggregateFunctionKeys: Object.keys(AGGREGATE_FUNCTIONS).map(key => AGGREGATE_FUNCTIONS[key].key)
}
