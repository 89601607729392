const settings = {
  open: {
    key: 'open',
    label: 'Open'
  },
  responded: {
    key: 'responded',
    label: 'Responded'
  },
  inNegotiation: {
    key: 'inNegotiation',
    label: 'In Negotiation'
  },
  accepted: {
    key: 'accepted',
    label: 'Accepted'
  },
  declined: {
    key: 'declined',
    label: 'Declined'
  }
}

const keys = Object.values(settings).map(o => o.key)
const labels = Object.values(settings).map(o => o.label)
const statuses = Object.values(settings).reduce((m, o) => Object.assign(m, { [o.label]: o.key }), {})

export default {
  settings,
  keys,
  labels,
  statuses
}
