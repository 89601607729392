<template>
  <div>
    <ul class="tabs flex">
      <li
        v-for="(tab, index) in availableTabs"
        :key="index"
        class="item px-6 gap-x-2 flex items-center cursor-pointer"
        :class="{ 'active': activeTabIndex === index }"
        @click="selectTab(index)"
      >
        {{ tab.title }} <span class="counter rounded-full p-0.5 px-1">{{ totals[tab.key] }}</span>
      </li>
    </ul>
  </div>
</template>

<script>
import { GlobalSearchAccess } from '@/components/global-search/mixins'

export default {
  name: 'GlobalSearchTabs',

  mixins: [
    GlobalSearchAccess
  ],

  props: {
    totals: {
      type: Object,
      required: true
    },
    initialTab: {
      type: String,
      default: 'all'
    }
  },

  data () {
    return {
      activeTabIndex: 0
    }
  },

  computed: {
    tabs () {
      return [
        {
          title: 'All',
          key: 'all',
          component: 'all',
          display: true
        },
        {
          title: 'Request For Proposals',
          key: 'requestForProposals',
          component: 'request-for-proposals',
          display: this.hasAccess.requestForProposals && this.enabledFeatures.requestForProposals
        },
        {
          title: 'Campaigns',
          key: 'campaigns',
          component: 'campaigns',
          display: true
        },
        {
          title: 'Clients',
          key: 'clients',
          component: 'clients',
          display: true
        },
        {
          title: 'Files',
          key: 'files',
          component: 'files',
          display: this.hasAccess.mediaAssets && this.enabledFeatures.mediaAssets
        },
        {
          title: 'Contacts',
          key: 'contacts',
          component: 'contacts',
          display: true
        }
      ]
    },

    availableTabs () {
      return this.tabs.filter(tab => tab.display)
    }
  },

  created () {
    this.activeTabIndex = this.tabs.findIndex(tab => tab.key === this.initialTab)
  },

  methods: {
    selectTab (index) {
      this.activeTabIndex = index
      this.$emit('selected', this.availableTabs[index].key)
    }
  }
}

</script>

<style scoped>
@import "@/components/global-search/styles/global-search-results.css";
</style>
