<template>
  <div>
    <div class="notification-wrapper">
      <div :class="{ 'blue-dot': notification.isUnread }" />
      <div class="content">
        <div class="header">
          <div class="flex items-left space-x-2">
            {{ notification.createdAt | short_date }}
          </div>
          <div class="date">
            {{ notification.createdAt | time }}
          </div>
        </div>
        <div class="text">
          <div
            v-if="notification.notificationType === 'push_data_submitted'"
            class="text"
          >
            <p class="message">
              Campaign:
              <router-link
                :to="{ name: 'CampaignSummary', params: { id: notification.campaignId }}"
                @click.native="handleClose()"
              >
                {{ notification.campaignName }}
              </router-link>
              has been submitted successfully and
              {{ notification.externalSystemName | a_or_an }} {{ notification.externalSystemName }}
              Order has been created! Go to the campaign for more details.
            </p>
            <p class="company-group">
              {{ notification.companyGroup }}
            </p>
          </div>
          <div
            v-if="notification.notificationType === 'push_data_failed'"
            class="text"
          >
            <p class="message">
              Campaign:
              <router-link
                :to="{ name: 'CampaignSummary', params: { id: notification.campaignId }}"
                @click.native="handleClose()"
              >
                {{ notification.campaignName }}
              </router-link>
              could not be submitted and
              {{ notification.externalSystemName | a_or_an }} {{ notification.externalSystemName }}
              Order was not created. To review the reason for the error, please go to the Campaign for more details.
              A task with a due date of tomorrow has been created to help track this issue.
            </p>
            <p class="company-group">
              {{ notification.companyGroup }}
            </p>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>

export default {
  props: {
    notification: {
      type: Object,
      required: false,
      default: null
    }
  },
  methods: {
    handleClose () {
      this.$emit('close')
    }
  }
}
</script>

<style scoped>

.notification-wrapper {
  display: flex;
  padding-top: 20px;
}

.content {
  flex-grow: 1;
}

.header {
  display: flex;
  justify-content: space-between;
  color: rgb(97, 91, 91);
}

.message {
  margin-bottom: 20px;
}

.company-group {
  font-style: italic;
  color: rgb(97, 91, 91);
}

.space-x-2 {
  column-gap: 0.5rem;
}

.text {
  padding: 10px 0 10px 0 ;
  max-width: 415px;
  line-height: 1.5;
}

.blue-dot {
  min-width: 10px;
  height: 10px;
  border-radius: 100%;
  margin-top: 10px;
  margin-right: 8px;
  background-color: #2e76bc;
}

</style>
