<template>
  <a-dialog
    type="default"
    class="Search"
    primary-button-type="default"
    button-text="Close"
    vertical-height="shrink"
    horizontal-width="global-search"
    :button-action="handleClose"
    @close-dialog="handleClose"
  >
    <template #header>
      Search
    </template>

    <template #content>
      <div class="global-search">
        <div class="search-holder">
          <search-input
            ref="searchInput"
            v-model="searchString"
            placeholder="Search across campaigns, clients, contacts, or files..."
            :show-cancel="true"
            @input="handleSearch"
          >
            <template #left-side>
              <ui-icon
                name="search"
                size="xsmall"
              />
            </template>

            <template #right-side>
              <ui-icon
                name="shortcut"
                size="xsmall"
                state="dark"
              >
                k
              </ui-icon>
            </template>
          </search-input>
        </div>

        <div
          v-if="isSearching"
          class="flex items-center justify-center mt-8 mb-3"
        >
          <loading-spinner />
        </div>

        <div v-if="!isSearching && hasResults">
          <div class="tab-holder">
            <global-search-tabs
              :totals="totals"
              :initial-tab="activeTab"
              @selected="handleTabSelection"
            />
          </div>

          <div
            ref="results"
            class="results-holder mt-6"
          >
            <div v-if="activeTab === 'all'">
              <div v-if="totals.all">
                <all-search-results
                  :results="results"
                  :totals="totals"
                  :search-string="searchString"
                  @go-to-all="goToAll"
                  @navigation="handleClose"
                />
              </div>
              <global-search-empty v-else />
            </div>

            <div v-if="activeTab === 'requestForProposals'">
              <global-search-list
                :total="totals.requestForProposals"
                :data="results.requestForProposals.data"
                @view-all="goToAll('request-for-proposals')"
              >
                <template #row="item">
                  <request-for-proposal-search-result
                    :request-for-proposal="item"
                    :search-string="searchString"
                    @navigation="handleClose"
                  />
                </template>
              </global-search-list>
            </div>

            <div v-if="activeTab === 'campaigns'">
              <global-search-list
                :total="totals.campaigns"
                :data="results.campaigns.campaigns"
                @view-all="goToAll('campaigns')"
              >
                <template #row="item">
                  <campaigns-search-result
                    :campaign="item"
                    :search-string="searchString"
                    @navigation="handleClose"
                  />
                </template>
              </global-search-list>
            </div>

            <div v-if="activeTab === 'clients'">
              <global-search-list
                :total="totals.clients"
                :data="results.clients.clients"
                @view-all="goToAll('clients')"
              >
                <template #row="item">
                  <clients-search-result
                    :client="item"
                    :search-string="searchString"
                    @navigation="handleClose"
                  />
                </template>
              </global-search-list>
            </div>

            <div v-if="activeTab === 'files'">
              <global-search-list
                :total="totals.files"
                :data="results.files.data"
                @view-all="goToAll('files')"
              >
                <template #row="item">
                  <files-search-result
                    :file="item"
                    :search-string="searchString"
                    @navigation="handleClose"
                  />
                </template>
              </global-search-list>
            </div>

            <div v-if="activeTab === 'contacts'">
              <global-search-list
                :total="totals.contacts"
                :data="results.contacts.data"
                @view-all="goToAll('contacts')"
              >
                <template #row="item">
                  <contacts-search-result
                    :contact="item"
                    :search-string="searchString"
                    @navigation="handleClose"
                  />
                </template>
              </global-search-list>
            </div>
          </div>
        </div>
      </div>
    </template>
  </a-dialog>
</template>

<script>
import { debounce } from 'lodash'
import { GlobalSearchAccess } from '@/components/global-search/mixins'
import GlobalSearchTabs from '@/components/global-search/GlobalSearchTabs.vue'
import GlobalSearchList from '@/components/global-search/GlobalSearchList.vue'
import GlobalSearchEmpty from '@/components/global-search/GlobalSearchEmpty.vue'
import AllSearchResults from '@/components/global-search/AllSearchResults.vue'
import RequestForProposalSearchResult from '@/components/global-search/RequestForProposalsSearchResult.vue'
import CampaignsSearchResult from '@/components/global-search/CampaignsSearchResult.vue'
import ClientsSearchResult from '@/components/global-search/ClientsSearchResult.vue'
import FilesSearchResult from '@/components/global-search/FilesSearchResult.vue'
import ContactsSearchResult from '@/components/global-search/ContactsSearchResult.vue'

export default {
  name: 'GlobalSearchModal',

  components: {
    GlobalSearchTabs,
    GlobalSearchList,
    GlobalSearchEmpty,
    AllSearchResults,
    RequestForProposalSearchResult,
    CampaignsSearchResult,
    ClientsSearchResult,
    FilesSearchResult,
    ContactsSearchResult
  },

  mixins: [
    GlobalSearchAccess
  ],

  data () {
    return {
      searchString: '',
      isSearching: false,
      searchObjectLimit: 10,
      activeTab: 'all',
      results: null
    }
  },

  computed: {
    hasResults () { return this.results && Object.keys(this.results).length },

    totals () {
      return {
        all:
          (this.results.requestForProposals?.count || 0) +
          (this.results.campaigns?.num || 0) +
          (this.results.clients?.num || 0) +
          (this.results.files?.count || 0) +
          (this.results.contacts?.count || 0),
        requestForProposals: this.results.requestForProposals?.count || 0,
        campaigns: this.results.campaigns?.num || 0,
        clients: this.results.clients?.num || 0,
        files: this.results.files?.count || 0,
        contacts: this.results.contacts?.count || 0,
      }
    }
  },

  methods: {
    focusSearchInput () {
      this.$refs.searchInput?.focusInput()
    },

    handleSearch: debounce(async function (value) {
      this.results = null
      this.isSearching = false

      if (value) {
        this.isSearching = true

        try {
          this.results = {}
          const requests = []

          if (this.hasAccess.requestForProposals && this.enabledFeatures.requestForProposals) {
            requests.push(this.fetchRequestForProposals(value))
          }

          if (this.hasAccess.campaigns) {
            requests.push(this.fetchCampaigns(value))
          }

          if (this.hasAccess.clients) {
            requests.push(this.fetchClients(value))
          }

          if (this.hasAccess.mediaAssets && this.enabledFeatures.mediaAssets) {
            requests.push(this.fetchFiles(value))
          }

          if (this.hasAccess.contacts) {
            requests.push(this.fetchContacts(value))
          }

          const responses = await Promise.all(requests)
          this.results = {
            requestForProposals: responses[0],
            campaigns: responses[1],
            clients: responses[2],
            files: responses[3],
            contacts: responses[4]
          }

          this.isSearching = false
        } catch (error) {
          this.isSearching = false
        }
      } else {
        this.resetSearch()
        this.activeTab = 'all'
      }
    }, 1000),

    fetchRequestForProposals (search) {
      const params = {
        limit: this.searchObjectLimit,
        page: 1,
        search,
        viewFilter: this.requestForProposalsViewFilter,
        sortField: 'updatedAt',
        sortDirection: 'desc'
      }

      if (this.$permission.hasPermission('RequestForProposalViewForOwnCompany')) {
        params.companyId = this.companyId
      }

      if (this.$permission.hasPermission('RequestForProposalViewForOwnGroup') || this.$permission.hasPermission('RequestForProposalViewOwn')) {
        params.companyId = this.companyId
        params.companyGroupId = this.companyGroupId
      }

      return this.$api.requestForProposals.find(params)
    },

    fetchCampaigns (search) {
      const params = {
        limit: this.searchObjectLimit,
        page: 1,
        search,
        viewFilter: this.campaignsViewFilter,
        sort: 'updatedAt',
        sortDesc: true,
        companyId: this.companyId
      }

      if (this.$permission.hasPermission('CampaignViewForOwnGroup')) {
        params.companyId = this.companyId
        params.companyGroupId = this.companyGroupId
      }

      return this.$api.campaigns.find(params)
    },

    fetchClients (search) {
      const params = {
        limit: this.searchObjectLimit,
        page: 1,
        search,
        viewFilter: this.clientsViewFilter,
        sort: 'lastUpdated',
        sortDesc: true,
        companyId: this.companyId
      }

      if (this.$permission.hasPermission('ClientViewForOwnGroup')) {
        params.companyGroupId = this.companyGroupId
        params.companyGroupId = this.companyGroupId
      }

      return this.$api.clients.find(params)
    },

    fetchFiles (search) {
      const params = {
        currentPage: 1,
        sort: 'updatedAt',
        sortDir: 'desc'
      }

      if (this.$permission.hasPermission('AssetLibraryViewForOwnCompany')) {
        params.companyId = this.companyId
      }

      if (this.$permission.hasPermission('AssetLibraryViewForOwnGroup')) {
        params.companyId = this.companyId
        params.companyGroupId = this.companyGroupId
      }

      return this.$api.mediaAssets.list(params)
        .then(({ data }) => {
          const files = data
            .filter((f) => {
              const fileName = f.mediaAsset.filename.toLowerCase()
              return fileName.includes(search.toLowerCase()) && f.mediaAsset.isDeleted === false
            })

          return {
            count: files.length,
            data: files
          }
        })
    },

    fetchContacts (search) {
      const params = {
        limit: this.searchObjectLimit,
        page: 1,
        search,
        viewFilter: 'all',
        companyId: this.companyId,
        sortField: 'updatedAt',
        sortDirection: 'desc'
      }

      if (this.$permission.hasPermission('ContactViewForOwnCompany')) {
        params.companyId = this.companyId
      }

      if (this.$permission.hasPermission('ContactViewForOwnGroup') || this.$permission.hasPermission('ContactViewOwn')) {
        params.companyId = this.companyId
        params.companyGroupId = this.companyGroupId
      }

      return this.$api.contacts.find(params)
    },

    handleTabSelection (tab) {
      this.activeTab = tab
      this.$refs.results.scrollTop = 0
    },

    goToAll (route) {
      let routeName

      switch (route) {
        case 'request-for-proposals':
          routeName = this.requestForProposalsViewFilter === 'all' ? 'WorkspaceAllRequestForProposals' : 'WorkspaceMyRequestForProposals'
          break

        case 'campaigns':
          routeName = this.campaignsViewFilter === 'all' ? 'WorkspaceAllCampaigns' : 'WorkspaceMyCampaigns'
          break

        case 'clients':
          routeName = this.clientsViewFilter === 'all' ? 'WorkspaceAllClients' : 'WorkspaceMyClients'
          break

        case 'files':
          routeName = 'AssetLibrary'
          break

        case 'contacts':
          routeName = 'WorkspaceAllContacts'
          break
      }

      this.$router.push({
        name: routeName,
        query: { search: this.searchString }
      })

      this.resetSearch()
      this.handleClose()
    },

    resetSearch () {
      this.searchString = ''
      this.results = null
    },

    handleClose () {
      this.activeTab = 'all'
      this.$emit('close')
      this.$bus.$emit('global-search-active', false)
    }
  }
}

</script>

<style scoped>
@import "@/components/global-search/styles/global-search-results.css";
</style>
