const settings = {
  email: {
    key: 'email',
    label: 'Email'
  },
  phone: {
    key: 'phone',
    label: 'Phone'
  }
}

const keys = Object.values(settings).map(o => o.key)
const types = Object.values(settings).reduce((m, o) => Object.assign(m, { [o.label]: o.key }), {})

export default {
  settings,
  keys,
  types
}
