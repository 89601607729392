<template>
  <div class="all-results">
    <global-search-list
      v-if="results.requestForProposals?.data.length"
      title="Request For Proposals"
      :total="totals.requestForProposals"
      :data="getTopThree(results.requestForProposals.data)"
      @view-all="goToAll('request-for-proposals')"
    >
      <template #row="item">
        <request-for-proposal-search-result
          :request-for-proposal="item"
          :search-string="searchString"
          @navigation="handleClose"
        />
      </template>
    </global-search-list>

    <global-search-list
      v-if="results.campaigns?.campaigns.length"
      title="Campaigns"
      :total="totals.campaigns"
      :data="getTopThree(results.campaigns.campaigns)"
      @view-all="goToAll('campaigns')"
    >
      <template #row="item">
        <campaigns-search-result
          :campaign="item"
          :search-string="searchString"
          @navigation="handleClose"
        />
      </template>
    </global-search-list>

    <global-search-list
      v-if="results.clients?.clients.length"
      title="Clients"
      :total="totals.clients"
      :data="getTopThree(results.clients.clients)"
      @view-all="goToAll('clients')"
    >
      <template #row="item">
        <clients-search-result
          :client="item"
          :search-string="searchString"
          @navigation="handleClose"
        />
      </template>
    </global-search-list>

    <global-search-list
      v-if="results.files?.data.length"
      title="Files"
      :total="totals.files"
      :data="getTopThree(results.files.data)"
      @view-all="goToAll('files')"
    >
      <template #row="item">
        <files-search-result
          :file="item"
          :search-string="searchString"
          @navigation="handleClose"
        />
      </template>
    </global-search-list>

    <global-search-list
      v-if="results.contacts?.data.length"
      title="Contacts"
      :total="totals.contacts"
      :data="getTopThree(results.contacts.data)"
      @view-all="goToAll('contacts')"
    >
      <template #row="item">
        <contacts-search-result
          :contact="item"
          :search-string="searchString"
          @navigation="handleClose"
        />
      </template>
    </global-search-list>
  </div>
</template>

<script>
import GlobalSearchList from '@/components/global-search/GlobalSearchList.vue'
import RequestForProposalSearchResult from '@/components/global-search/RequestForProposalsSearchResult.vue'
import CampaignsSearchResult from '@/components/global-search/CampaignsSearchResult.vue'
import ClientsSearchResult from '@/components/global-search/ClientsSearchResult.vue'
import FilesSearchResult from '@/components/global-search/FilesSearchResult.vue'
import ContactsSearchResult from '@/components/global-search/ContactsSearchResult.vue'

export default {
  name: 'AllSearchResults',

  components: {
    GlobalSearchList,
    RequestForProposalSearchResult,
    CampaignsSearchResult,
    ClientsSearchResult,
    FilesSearchResult,
    ContactsSearchResult
  },

  props: {
    results: {
      type: Object,
      required: true
    },
    totals: {
      type: Object,
      required: true
    },
    searchString: {
      type: String,
      default: ''
    }
  },

  methods: {
    getTopThree (results) {
      return results.slice(0, 3)
    },

    goToAll (route) {
      this.$emit('go-to-all', route)
    },

    handleClose (route) {
      this.$emit('navigation', route)
    }
  }
}
</script>

<style scoped>
@import "@/components/global-search/styles/global-search-results.css";
</style>
