<template>
  <div>
    <div class="w-full flex pt-5">
      <div :class="{ 'is-unread': notification.isUnread }" />

      <div class="mr-4">
        <tasks-icon
          w="30"
          h="30"
          status="inactive"
          class="tasks-icon"
        />
      </div>

      <div class="w-full">
        <div class="flex justify-between header">
          <span>{{ notification.createdAt | short_date }}</span>
          <span>{{ notification.createdAt | time }}</span>
        </div>
      </div>
    </div>
    <div class="details">
      <b>Reminder</b> your campaign
      <a-link @click="goToCampaign(notification.campaignId)">
        {{ notification.campaignName }}
      </a-link> is ending on {{ notification.expirationDate }}
      <warning-icon
        w="20"
        h="20"
        class="warning-icon"
      />
      <a-link @click="goToCampaign(notification.campaignId)">
        View Campaign
      </a-link>
    </div>
  </div>
</template>

<script>
import dayjs from 'dayjs'
import relativeTime from 'dayjs/plugin/relativeTime'
import { TasksIcon, WarningIcon } from '@/components/icons'

dayjs.extend(relativeTime)

export default {
  components: {
    TasksIcon,
    WarningIcon
  },

  props: {
    notification: {
      type: Object,
      required: false,
      default: null
    }
  },

  methods: {
    goToCampaign (id) {
      this.$router.push({ name: 'CampaignSummary', params: { id } })
      this.$emit('close')
    }

  }
}
</script>

<style scoped>
.is-unread {
  min-width: 10px;
  height: 10px;
  border-radius: 100%;
  margin-top: 10px;
  margin-right: 8px;
  background-color: #2e76bc;
}

.header {
  color: rgb(97, 91, 91);
}

.text {
  padding: 10px 0 10px 0 ;
  max-width: 415px;
  line-height: 1.5;
}

.details {
  position: relative;
  top:0;
  left: 10%;
  margin-bottom: 10px;
  width: 90%;
}

.tasks-icon >>> circle,
.warning-icon {
  fill: var(--text-warning-color);
}

</style>
