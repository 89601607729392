<template>
  <global-search-modal
    v-if="open"
    ref="modal"
    @close="close"
  />
</template>

<script>
import GlobalSearchModal from '@/components/global-search/GlobalSearchModal.vue'

export default {
  name: 'GlobalSearch',

  components: {
    GlobalSearchModal
  },

  data () {
    return {
      open: false
    }
  },

  watch: {
    open (val) {
      if (val) {
        setTimeout(this.focusSearchInput, 500)
        this.$bus.$emit('global-search-active', val)
      }
    }
  },

  async created () {
    this.$bus.$on('shortcut:Meta+k', () => {
      if (!this.$store.state.User.isLoaded) return
      if (this.$route.name === 'SwitchGroup') return

      this.open = true
      this.$nextTick(() => {
        this.$refs.modal.resetSearch()
      })
    })
  },

  methods: {
    focusSearchInput () {
      this.$refs.modal.focusSearchInput()
    },

    close () {
      this.$refs.modal.resetSearch()
      this.open = false
    }
  }
}
</script>

<style scoped>
</style>
