<template>
  <span
    class="h-6 inline-flex items-center flex-nowrap justify-center px-2.5 text-xs"
    :class="classes"
    v-on="$listeners"
  >
    <slot />
  </span>
</template>

<script>

const VARIANTS = new Set(['default', 'primary', 'secondary', 'danger', 'info', 'pill', 'warning', 'auxiliary'])

export default {
  props: {
    variant: {
      type: String,
      default: 'default',
      validator: variant => VARIANTS.has(variant)
    }
  },

  computed: {
    classes: {
      get () {
        return VARIANTS.has(this.variant) && this.variant !== 'default'
          ? ['badge', `badge-${this.variant}`]
          : ['badge']
      }
    }
  }
}
</script>

<style scoped>
  .badge {
    font-size: 0.625rem;
    line-height: 1.25rem;
    font-weight: 500;
    border-radius: var(--border-radius);
    background-color: var(--badge-background-color);
    color: var(--badge-text-color);
  }

  .badge-primary {
    background-color: var(--badge-primary-background-color);
    color: var(--badge-primary-text-color);
  }

  .badge-secondary {
    background-color: var(--badge-secondary-background-color);
    color: var(--badge-secondary-text-color);
  }

  .badge-danger {
    background-color: var(--badge-danger-background-color);
    color: var(--badge-danger-text-color);
  }

  .badge-info {
    background-color: var(--badge-info-background-color);
    color: var(--badge-info-text-color);
  }

  .badge-pill {
    background-color: var(--badge-pill-background-color);
    color: var(--badge-pill-text-color);
  }

  .badge-warning {
    background-color: var(--badge-warning-background-color);
    color: var(--badge-warning-text-color);
  }

  .badge-auxiliary {
    background-color: var(--badge-auxiliary-background-color);
    color: var(--badge-auxiliary-text-color);
    border: 1px solid var(--badge-auxiliary-text-color);
  }
</style>
