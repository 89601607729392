<template>
  <div>
    <div class="w-full flex pt-5">
      <div :class="{ 'is-unread': notification.isUnread }" />

      <div class="mr-4">
        <request-for-proposal-notification-icon :status="status" />
      </div>

      <div class="w-full">
        <div class="flex justify-between header">
          <span>{{ notification.createdAt | short_date }}</span>
          <span>{{ notification.createdAt | time }}</span>
        </div>

        <div class="text">
          <div v-if="status === 'default'">
            <p>You have a request for proposal due {{ notification.requestForProposal.dueDate | short_date }}</p>
          </div>
          <div v-if="status === 'today'">
            <p><strong>Due Today</strong> your request for proposal is due today: {{ notification.requestForProposal.dueDate | short_date }} &#10071;&#10071;</p>
          </div>
          <div v-if="status === 'overdue'">
            <p><strong>Past Due</strong>  your request for proposal is past due: {{ notification.requestForProposal.dueDate | short_date }} &#10071;&#10071;</p>
          </div>
          <a
            class="block cursor-pointer underline my-2"
            @click="gotoRequestForProposal"
          >View Request For Proposal</a>
        </div>
      </div>
    </div>
    <div class="details p-3">
      {{ notification.requestForProposal.name }}
    </div>
  </div>
</template>

<script>
import dayjs from 'dayjs'
import relativeTime from 'dayjs/plugin/relativeTime'
import { RequestForProposalNotificationIcon } from '@/components/icons'

dayjs.extend(relativeTime)

export default {
  components: {
    RequestForProposalNotificationIcon
  },

  props: {
    notification: {
      type: Object,
      required: false,
      default: null
    }
  },

  computed: {
    status () {
      if (!this.notification.requestForProposal.dueDate) return 'default'

      const diff = this.getDiffForDueDate(this.notification.requestForProposal.dueDate)

      if (diff > 0) {
        return 'default'
      } else if (diff === 0) {
        return 'today'
      } else {
        return 'overdue'
      }
    },
  },

  methods: {
    getDiffForDueDate (dueDate) {
      const today = dayjs().startOf('day')
      const due = dayjs(dueDate).startOf('day')
      const diff = due.diff(today, 'day')
      return diff
    },

    gotoRequestForProposal () {
      this.$router.push({
        name: 'EditRequestForProposal',
        params: {
          id: this.notification.notifiableId
        }
      }).catch(() => {})
      this.$emit('close')
    }
  }
}
</script>

<style scoped>
.is-unread {
  min-width: 10px;
  height: 10px;
  border-radius: 100%;
  margin-top: 10px;
  margin-right: 8px;
  background-color: #2e76bc;
}

.header {
  color: rgb(97, 91, 91);
}

.text {
  padding: 10px 0 10px 0 ;
  max-width: 415px;
  line-height: 1.5;
}

.details {
  position: relative;
  left: 10%;
  margin-bottom: 10px;
  width: 90%;
  background-color: rgb(232, 232, 232);
}
</style>
