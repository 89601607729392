import dayjs from 'dayjs'
import { upperFirst } from 'lodash-es'

const VOWELS = /^[aeiou]/i

const FormattersPlugin = {
  install (Vue, options) {
    Vue.filter('a_or_an', (str, shouldUppercase = false) => {
      let indefiniteArticle = 'a'

      if (VOWELS.test(str)) {
        indefiniteArticle = 'an'
      }

      if (shouldUppercase) {
        indefiniteArticle = upperFirst(indefiniteArticle)
      }

      return indefiniteArticle
    })

    Vue.filter('number_with_commas', (value) => {
      const parts = value.toString().split('.')
      parts[0] = parts[0].replace(/\B(?=(\d{3})+(?!\d))/g, ',')
      return parts.join('.')
    })

    Vue.prototype.$formatGoogleAddress = (address, clientName, latlngFunc = true) => {
      const addressComponents = {
        formatted: address.formatted_address,
        googlePlaceId: address.place_id,
        lat: latlngFunc ? address.geometry.location.lat() : address.geometry.location.lat,
        lng: latlngFunc ? address.geometry.location.lng() : address.geometry.location.lng,
        name: (
          address.types !== undefined && (address.types.includes('street_address') ||
          address.types.includes('premise'))
        ) && clientName
          ? clientName
          : address.name
      }
      address.address_components.forEach((component) => {
        if (component.types.includes('street_number')) {
          addressComponents.streetNumber = component.long_name
        } else if (component.types.includes('route')) {
          addressComponents.street = component.long_name
        } else if (component.types.includes('subpremise')) {
          addressComponents.subpremise = component.long_name
        } else if (component.types.includes('locality')) {
          addressComponents.city = component.long_name
        } else if (component.types.includes('sublocality_level_1') && !addressComponents.city) {
          addressComponents.city = component.long_name
        } else if (component.types.includes('administrative_area_level_1')) {
          addressComponents.state = component.short_name
        } else if (component.types.includes('postal_code')) {
          addressComponents.zip = component.long_name
        } else if (component.types.includes('country')) {
          addressComponents.country = component.short_name
        }
      })

      return addressComponents
    }

    function executeDateFilter (value, format) {
      try {
        const date = dayjs(value)
        if (!date.isValid()) return ''
        return date.format(format)
      } catch (err) {
        return ''
      }
    }

    Vue.filter('pretty_date', value => {
      return executeDateFilter(value, 'dddd, MMM D, YYYY')
    })

    Vue.filter('pretty_date_with_full_month', value => {
      return executeDateFilter(value, 'dddd, MMMM D, YYYY')
    })

    Vue.filter('short_date', value => {
      return executeDateFilter(value, 'MM/DD/YYYY')
    })

    Vue.filter('short_date_with_time', value => {
      return executeDateFilter(value, 'MM/DD h:mm A')
    })

    Vue.filter('time', value => {
      return executeDateFilter(value, 'h:mm A')
    })

    Vue.filter('short_pretty_date', value => {
      return executeDateFilter(value, 'MMM D, YYYY')
    })

    Vue.filter('short_date_12hour_time', value => {
      return executeDateFilter(value, 'MM/DD/YYYY h:mm A')
    })

    Vue.filter('phone', number => {
      if (number === null) return null

      const digits = number.match(/\d+/g)
      if (digits === null) return null

      let numericValue = digits.join('')
      let formattedString = ''

      // Area code
      if (numericValue.length >= 3) formattedString += '(' + numericValue.substring(0, 3) + ')'
      else formattedString = numericValue
      numericValue = numericValue.substring(3)

      // First three digits
      if (numericValue.length >= 4) formattedString += ' ' + numericValue.substring(0, 3) + '-'
      else formattedString += ' ' + numericValue
      numericValue = numericValue.substring(3)

      // Next four digits
      formattedString += numericValue.substring(0, 4)
      numericValue = numericValue.substring(4)

      // Remaining digits
      if (numericValue.length > 0) formattedString += ' ext. ' + numericValue

      return formattedString.trim()
    })

    Vue.prototype.$calculateMonthlyDuration = (startDate, endDate) => {
      const startDateObj = dayjs(startDate)
      const endDateObj = dayjs(endDate).add(1, 'day')
      const duration = endDateObj.diff(startDateObj, 'months', true)

      if (duration < 0) return duration

      const decimal = duration - Math.floor(duration)
      if (decimal <= 0.1) return Math.floor(duration)
      else if (decimal >= 0.9) return Math.ceil(duration)

      return Math.max(duration, 1)
    }

    Vue.prototype.$stripUtmString = (url) => {
      const parser = url.split('?')
      if (parser[1]) {
        const params = parser[1].split('&')
        let queryString = ''
        for (let i = 0; i < params.length; i++) {
          const pair = params[i].split('=')
          if (!pair[0].includes('utm_')) { // removing any utm_ queries
            if (queryString === '') {
              queryString += '?' + pair[0] + '=' + decodeURIComponent(pair[1])// sanitize query values
            } else {
              queryString += '&' + pair[0] + '=' + decodeURIComponent(pair[1])// sanitize query values
            }
          }
        }
        return parser[0] + queryString
      }
      return url
    }

    Vue.prototype.$cleanUrl = (baseUrl, urlPath) => {
      const base = baseUrl.trim().replace(/\/$/, '')
      const path = urlPath.trim().replace(/^\//, '')

      const url = `${base}/${path}`

      return url
    }
  }
}

export default FormattersPlugin
