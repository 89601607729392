<template>
  <div
    class="card mb-2 flex items-center justify-between gap-2 p-2 cursor-pointer"
    @click="handleNavigation"
  >
    <div class="flex items-center gap-2">
      <div>
        <img
          v-if="requestForProposal.client?.logoUrl"
          class="avatar"
          :src="requestForProposal.client.logoUrl"
        >
        <div
          v-else
          class="avatar-letter"
        >
          {{ requestForProposal.client?.name[0] || '' }}
        </div>
      </div>

      <div>
        <div class="name">
          <highlight-query
            :text="requestForProposal.name"
            :search-string="searchString"
          />
        </div>

        <small
          v-if="requestForProposal.client"
          class="sub-text inline-block mt-2"
        >
          <highlight-query
            :text="requestForProposal.client.name"
            :search-string="searchString"
          />
        </small>
      </div>
    </div>

    <div class="ml-2">
      <status-badge variant="info">
        {{ statusLabel(requestForProposal.status) }}
      </status-badge>
    </div>
  </div>
</template>

<script>
import { requestForProposalStatus } from '@advisr/system-config'
import { GlobalSearchAccess } from '@/components/global-search/mixins'

export default {
  name: 'RequestForProposalsSearchResult',

  mixins: [
    GlobalSearchAccess
  ],

  props: {
    requestForProposal: {
      type: Object,
      required: true
    },
    searchString: {
      type: String,
      default: ''
    }
  },

  methods: {
    statusLabel (key) {
      return requestForProposalStatus.settings[key].label
    },

    handleNavigation () {
      this.$emit('navigation')
      this.$router.push({
        name: 'EditRequestForProposal',
        params: { id: this.requestForProposal.id }
      })
    }
  }
}
</script>

<style scoped>
@import "@/components/global-search/styles/global-search-results.css";
</style>
