<template>
  <div class="empty flex items-center justify-center w-full h-32">
    <div class="flex flex-col items-center">
      <ui-icon
        name="search"
        size="large"
      />
      <p class="mt-4">
        Nothing to see here, your search term did not return any results.
      </p>
    </div>
  </div>
</template>

<script>
export default {
  name: 'GlobalSearchEmpty'
}
</script>

<style scoped>
@import "@/components/global-search/styles/global-search-results.css";
</style>
