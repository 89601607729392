export default [
  {
    path: 'fulfillment-vendors',
    component: { render: (h) => h('router-view') },
    children: [
      {
        name: 'AdminFulfillmentVendor',
        path: '',
        component: () => import('@/views/admin/fulfillmentVendors/List.vue'),
      },
      {
        path: 'new',
        name: 'AdminFulfillmentVendorAdd',
        component: () => import('@/views/admin/fulfillmentVendors/Add.vue'),
        meta: {
          headerText: 'New Fulfillment Vendor',
          subNav: 'none',
          headerBackRoute: 'AdminFulfillmentVendor'
        }
      },
      {
        path: ':id',
        name: 'AdminFulfillmentVendorEdit',
        component: () => import('@/views/admin/fulfillmentVendors/Edit.vue'),
        meta: {
          headerText: 'Edit Fulfillment Vendor',
          subNav: 'none',
          headerBackRoute: 'AdminFulfillmentVendor'
        }
      }
    ],
    meta: {
      headerText: 'Fulfillment Vendor Admin',
      headerBackRoute: 'AdminHome'
    }
  }
]
