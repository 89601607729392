import Vue from 'vue'
import contacts from './contacts'
import products from './products'

function find (params = {}) {
  return Vue.api.get('/client/list-for-my-clients', { params })
}

export default {
  contacts,
  products,
  find
}
