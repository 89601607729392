<template>
  <component
    :is="component"
    :w="width"
    :h="height"
  />
</template>

<script>

import * as icons from './'

const iconMap = {
  Audio: 'FileAudioIcon',
  Video: 'FileVideoIcon',
  Image: 'FileImageIcon',
  External: 'ExternalDocumentIcon',
  Email: 'EmailIcon',
  Outlook: 'FileOutlookIcon',
  Excel: 'FileExcelIcon',
  PPT: 'FilePptIcon',
  Word: 'FileWordIcon',
  Text: 'FileTextIcon',
  PDF: 'FilePdfIcon',
  XML: 'FileXmlIcon',
  JSON: 'FileIcon',
  'Zip File': 'FileZipIcon',
  TVSchedule: 'FileXmlIcon',
  Schedule: 'FileXmlIcon'
}

const sizes = {
  'x-small': { width: '12', height: '12' },
  small: { width: '18', height: '18' },
  medium: { width: '24', height: '24' },
  large: { width: '30', height: '30' },
  'x-large': { width: '36', height: '36' }
}

export default {
  name: 'DynamicIcon',
  components: {
    ...icons
  },
  props: {
    size: {
      type: String,
      default: 'medium',
      validator: (size) => !!sizes[size]
    },
    type: {
      type: String,
      required: true,
      validator: (type) => !!iconMap[type]
    }
  },
  computed: {
    component () { return iconMap[this.type] },
    width () { return sizes[this.size].width },
    height () { return sizes[this.size].height }
  }
}

</script>
<style scoped>
.ppt-icon >>> g {
  fill: var(--text-warning-color)
}

.pdf-icon >>> g {
  fill: var(--text-error-color);
}

.file-image-icon >>> g {
  fill: var(--link-color);
}

.excel-icon >>> g {
  fill: var(--text-success-color)
}
</style>
