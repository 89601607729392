import Vue from 'vue'

function loadDashboardEmbedURL (id, params) {
  const path = `/reports/dashboards/${id}`
  return Vue.api.get(path, { params })
}

const salesActivities = {
  find: (params = {}) => {
    const path = '/reports/sales-activities'
    return Vue.api.get(path, { params })
  }
}

export default {
  loadDashboardEmbedURL,
  salesActivities
}
