<template>
  <button
    :class="buttonClasses"
    :disabled="disabled"
    :data-test="datatest"
    @click="onClick"
    @mousedown="$emit('mousedown', $event)"
  >
    <slot />
  </button>
</template>

<script>
import { includes } from 'lodash-es'

const VARIANTS = [
  'default', 'primary', 'secondary',
  'danger', 'action', 'pagination',
  'toggle-active', 'toggle-default',
  'transparent', 'login', 'warning',
  'hidden', 'text', 'pill', 'bordered'
]

const SIZES = [
  null, 'medium', 'small', 'xsmall'
]

export default {
  name: 'AButton',

  props: {
    datatest: {
      type: String,
      default: ''
    },

    disabled: {
      type: Boolean,
      default: false
    },

    height: {
      type: String,
      default: null
    },

    size: {
      type: String,
      default: null,
      validator: type => includes(SIZES, type)
    },

    variant: {
      type: String,
      default: 'default',
      validator: type => includes(VARIANTS, type)
    },

    to: {
      type: Object,
      default: null
    },

    href: {
      type: [String, null],
      default: null
    },

    target: {
      type: [String, null],
      default: null,
      validator: target => target === '_blank'
    },

    disableMargin: {
      type: Boolean,
      default: true
    },

    addMargin: {
      type: Boolean,
      default: false
    }
  },

  computed: {
    buttonClasses () {
      const classes = ['btn']
      if (this.variant) classes.push('btn-' + this.variant)
      else classes.push('btn-default')
      if (this.size) classes.push('btn-' + this.size)
      if (this.disabled) classes.push('disabled')
      if (this.addMargin) classes.push('margin')
      else if (this.resetMargin) classes.push('disable-margin')

      return classes
    }
  },
  methods: {
    onClick (event) {
      if (!this.to && !this.href) {
        this.$emit('click', event)
      } else if (this.target) {
        const href = this.href ?? this.$router.resolve(this.to).href
        window.open(href, '_blank')
      } else {
        this.$router.push(this.to ?? this.href)
      }
    }
  }
}
</script>

<style scoped>
.btn {
  font-family: var(--font-stack);
  text-decoration: none;
  border: none;
  cursor: pointer;
  text-align: center;
}

.btn.disabled,
.btn[disabled] {
  cursor: not-allowed;
}

.btn-default,
.btn-primary,
.btn-secondary,
.btn-login,
.btn-danger,
.btn-warning,
.btn-transparent,
.btn-text,
.btn-bordered {
  padding: 14px 24px;
  font-size: 13px;
  font-weight: 500;
  line-height: 13px;
  border-radius: var(--border-radius);
}

.btn-pill {
  padding-top: 0.5rem;
  padding-bottom: 0.5rem;
  padding-left: 0.5rem;
  padding-right: 0.5rem;
  border-radius: 9999px;
  background-color: var(--edit-link-background-color);
  color: var(--button-default-text-color);
  font-size: 13px;
  font-weight: 500;
  line-height: 15px;
}

.btn-bordered {
  background-color: var(--button-bordered-background-color);
  color: var(--button-bordered-text-color);
  fill: var(--button-bordered-text-color);
  border: 1px solid var(--button-bordered-border-color);
}

.btn-bordered:hover {
  background-color: var(--button-bordered-hover-background-color);
  color: var(--button-bordered-hover-text-color);
  fill: var(--button-bordered-hover-text-color);
}

.btn-bordered.disabled,
.btn-default[disabled] {
  background-color: var(--button-bordered-disabled-background-color);
  border-color: var(--button-bordered-disabled-border-color);
  color: var(--button-bordered-disabled-text-color);
  fill: var(--button-bordered-disabled-text-color);
}

.btn-bordered.btn-medium {
  padding: 7px 16px;
  font-size: 14px;
  font-weight: 500;
  line-height: 20px;
}

.btn-bordered.btn-small {
  padding: 4px 6px;
}

.btn-default {
  background-color: var(--button-default-background-color);
  color: var(--button-default-text-color);
}

.btn-default:hover {
  background-color: var(--button-default-hover-background-color);
  color: var(--button-default-hover-text-color);
}

.btn-default.disabled,
.btn-default[disabled] {
  background-color: var(--button-default-disabled-background-color);
  color: var(--button-default-disabled-text-color);
}

.btn-text {
  background-color: transparent;
  text-decoration: underline;
  color: var(--button-transparent-text-color);
}

.btn-text:hover {
  background-color: transparent;
  color: var(--button-transparent-hover-text-color);
}

.btn-text.disabled,
.btn-text[disabled] {
  background-color: transparent;
  color: var(--button-transparent-disabled-text-color);
}

.btn-primary {
  background-color: var(--button-primary-background-color);
  color: var(--button-primary-text-color);
}

.btn-primary:hover {
  background-color: var(--button-primary-hover-background-color);
  color: var(--button-primary-hover-text-color);
}

.btn-primary.disabled,
.btn-primary[disabled] {
  background-color: var(--button-primary-disabled-background-color);
  color: var(--button-primary-disabled-text-color);
}

.btn-secondary {
  background-color: var(--button-secondary-background-color);
  color: var(--button-secondary-text-color);
}

.btn-secondary:hover {
  background-color: var(--button-secondary-hover-background-color);
  color: var(--button-secondary-hover-text-color);
}

.btn-secondary.disabled,
.btn-secondary[disabled] {
  background-color: var(--button-secondary-disabled-background-color);
  color: var(--button-secondary-disabled-text-color);
}

.btn-login {
  background-color: var(--button-login-background-color);
  color: var(--button-login-text-color);
  fill: var(--button-login-text-color);
  border: 1px solid var(--border-color);
  padding: 10px 24px;
}

.btn-login:hover {
  background-color: var(--button-login-hover-background-color);
  color: var(--button-login-hover-text-color);
  fill: var(--button-login-hover-text-color);
}

.btn-login.disabled,
.btn-login[disabled] {
  background-color: var(--button-login-disabled-background-color);
  color: var(--button-login-disabled-text-color);
  fill: var(--button-login-disabled-text-color);
}

.btn-danger {
  background-color: var(--button-danger-background-color);
  color: var(--button-danger-text-color);
}

.btn-danger:hover {
  background-color: var(--button-danger-hover-background-color);
  color: var(--button-danger-hover-text-color);
}

.btn-danger.disabled,
.btn-danger[disabled] {
  background-color: var(--button-danger-disabled-background-color);
  color: var(--button-danger-disabled-text-color);
}

.btn-warning {
  background-color: var(--button-warning-background-color);
  color: var(--button-warning-text-color);
}

.btn-warning:hover {
  background-color: var(--button-warning-hover-background-color);
  color: var(--button-warning-hover-text-color);
}

.btn-warning.disabled,
.btn-warning[disabled] {
  background-color: var(--button-warning-disabled-background-color);
  color: var(--button-danger-warning-text-color);
}

.btn-transparent {
  background-color: var(--button-transparent-background-color);
  color: var(--button-transparent-text-color);
  fill: var(--button-transparent-text-color);
}

.btn-transparent:hover {
  background-color: var(--button-transparent-hover-background-color);
  color: var(--button-transparent-hover-text-color);
  fill: var(--button-transparent-hover-text-color);
}

.btn-transparent.disabled,
.btn-transparent[disabled] {
  background-color: var(--button-transparent-disabled-background-color);
  color: var(--button-transparent-disabled-text-color);
  fill: var(--button-transparent-disabled-text-color);
}

.btn-action {
  padding: 11px 18px;
  font-size: 13px;
  font-weight: 500;
  line-height: 13px;
  border-radius: 50px;
}

.btn-action {
  background-color: var(--button-action-background-color);
  color: var(--button-action-text-color);
}

.btn-action:hover {
  background-color: var(--button-action-hover-background-color);
  color: var(--button-action-hover-text-color);
}

.btn-action.disabled,
.btn-action[disabled] {
  background-color: var(--button-action-disabled-background-color);
  color: var(--button-action-disabled-text-color);
}

.btn-pagination {
  padding: 10px 15px;
  margin-right: 5px;
  font-size: 13px;
  font-weight: 500;
  line-height: 13px;
  border-radius: var(--border-radius);
  background-color: var(--button-pagination-background-color);
  color: var(--button-pagination-text-color);
}

.btn-pagination:hover {
  background-color: var(--button-pagination-hover-background-color);
  color: var(--button-pagination-hover-text-color);
}

.btn-pagination.disabled,
.btn-pagination[disabled] {
  background-color: var(--button-pagination-disabled-background-color);
  color: var(--button-pagination-disabled-text-color);
  cursor: default;
}

.btn-toggle-active {
  background-color: var(--button-toggle-active-background-color);
  color: var(--button-toggle-active-text-color);
}

.btn-toggle-active:hover {
  background-color: var(--button-toggle-active-hover-background-color);
  color: var(--button-toggle-active-hover-text-color);
}

.btn-toggle-active.disabled,
.btn-toggle-active[disabled] {
  background-color: var(--button-toggle-active-disabled-background-color);
  color: var(--button-toggle-active-disabled-text-color);
}

.btn-toggle-default {
  background-color: var(--button-toggle-default-background-color);
  color: var(--button-toggle-default-text-color);
}

.btn-toggle-default:hover {
  background-color: var(--button-toggle-default-hover-background-color);
  color: var(--button-toggle-default-hover-text-color);
}

.btn-toggle-default.disabled,
.btn-toggle-default[disabled] {
  background-color: var(--button-toggle-default-disabled-background-color);
  color: var(--button-toggle-default-disabled-text-color);
}

.btn-medium {
  padding: 8px 16px;
  line-height: 20px;
}

.btn-small {
  padding: 8px 16px 8px;
  font-size: 12px;
  font-weight: 500;
  line-height: 12px;
}

.btn-xsmall {
   padding: 7px 12px 7px;
   font-size: 11px;
   font-weight: 500;
   line-height: 11px;
}

.btn-hidden {
  padding: 0px;
  margin: 0px;
  height: 1px;
  width: 1px;
  opacity: 0;
}

.margin {
  margin: 0 10px;
}

.disable-margin {
  margin: 0;
}
</style>
