import Vue from 'vue'

function load (id) {
  const path = `/admin/reporting/looker-dashboards/${id}`
  return Vue.api.get(path)
}

function _import (id) {
  const path = `/admin/reporting/looker-dashboards/${id}/import`
  return Vue.api.post(path)
}

function refresh (id) {
  const path = `/admin/reporting/looker-dashboards/${id}/refresh`
  return Vue.api.post(path)
}

export default {
  load,
  import: _import,
  refresh
}
