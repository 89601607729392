import Vue from 'vue'

function list (params = {}) {
  const path = '/campaign-statuses'
  return Vue.api.get(path, { params })
}

export default {
  list
}
