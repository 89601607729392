<template>
  <div style="display: none;" />
</template>

<script>
import ShortcutDetector from '../libs/ShortcutDetector'

export default {
  name: 'ShortcutHandler',

  data () {
    return {
      shortcuts: [
        new ShortcutDetector(['Meta+k']),
        new ShortcutDetector(
          ['ArrowUp', 'ArrowUp', 'ArrowDown', 'ArrowDown', 'ArrowLeft', 'ArrowRight', 'ArrowLeft', 'ArrowRight', 'b', 'a', ' ', 'Enter'],
          'konami-code'
        )
      ]
    }
  },

  beforeMount () {
    document.addEventListener('keydown', this.handleKeyDown)
  },

  beforeDestroy () {
    document.removeEventListener('keydown', this.handleKeyDown)
  },

  methods: {
    handleKeyDown (event) {
      const isMeta = event.ctrlKey || event.metaKey
      const isCtrlAndMeta = event.ctrlKey && event.metaKey
      let key

      if (isMeta && (event.key === 'Meta' || event.key === 'Control')) {
        key = event.key
      }

      if (isMeta && (event.key !== 'Meta' && event.key !== 'Control')) {
        key = `Meta+${event.key}`
      }

      if (!isMeta) {
        key = event.key
      }

      if (isCtrlAndMeta && (event.key !== 'Control' && event.key !== 'Meta')) {
        key = `Ctrl+Meta+${event.key}`
      }

      this.shortcuts.forEach(shortcut => {
        shortcut.addKey(key)

        if (shortcut.isSequenceMatched()) {
          event.preventDefault()
          this.$bus.$emit(`shortcut:${shortcut.eventName}`)
        }
      })
    }
  }
}
</script>
