import Vue from 'vue'
import { api } from '@/config'
const { apiRoot } = api

const actions = {

  adminLoadAvailableReportingGroupsForCompany (context, companyId) {
    return new Promise((resolve, reject) => {
      Vue.http
        .get(apiRoot + '/admin/reporting/group/company-list', { params: { companyId } })
        .then(response => {
          resolve(response.body)
        })
        .catch(error => reject(error))
    })
  },

  adminLoadDefaultReportingGroupsForCompany (context, companyId) {
    return new Promise((resolve, reject) => {
      Vue.http
        .get(apiRoot + '/admin/reporting/group/company-default-list', { params: { companyId } })
        .then(response => {
          resolve(response.body)
        })
        .catch(error => reject(error))
    })
  }
}

export default actions
