<template>
  <div>
    <router-link
      :to="{ name: 'ClientMyCampaigns', params: { id } }"
      rel="noopener noreferrer"
    >
      <img
        v-if="logo"
        class="client-logo rounded-full"
        :src="logo"
        :alt="`Client ${name}`"
        data-test="client-logo"
      >
      <div
        v-else
        class="client-logo-letter d-flex items-center justify-center rounded-full white"
      >
        {{ name[0] }}
      </div>
    </router-link>
  </div>
</template>

<script>
export default {
  props: {
    id: {
      type: Number,
      required: true
    },
    logo: {
      type: String,
      default: null
    },
    name: {
      type: String,
      required: true
    }
  }
}
</script>

<style scoped>
.client-logo {
  width: 32px;
  height: 32px;
  object-fit: cover;
}

.client-logo-letter {
  width: 32px;
  height: 32px;
  background-color: var(--product-not-recommended-color);
  font-size: .875rem;
  line-height: 1.25rem;
  font-weight: 700;
}
</style>
