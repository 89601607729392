import Vue from 'vue'

function list (productId) {
  const path = `/admin/products/${productId}/fulfillment-vendors`
  return Vue.api.get(path)
}

function create (productId, data = {}) {
  const path = `/admin/products/${productId}/fulfillment-vendors`
  return Vue.api.post(path, { data })
}

function _delete (productId, fulfillmentVendorId) {
  const path = `/admin/products/${productId}/fulfillment-vendors/${fulfillmentVendorId}`
  return Vue.api.delete(path)
}

function update (productId, fulfillmentVendorId, data = {}) {
  const path = `/admin/products/${productId}/fulfillment-vendors/${fulfillmentVendorId}`
  return Vue.api.patch(path, { data })
}

function reorder (productId, fulfillmentVendorId, data = {}) {
  const path = `/admin/products/${productId}/fulfillment-vendors/${fulfillmentVendorId}/order`
  return Vue.api.patch(path, { data })
}

export default {
  list,
  create,
  delete: _delete,
  update,
  reorder
}
